import { PageProps } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import BannerRedesign from "../components/BannerRedesign";
import SectionEzDivisorConsultation from "../components/ReUsables/SectionEzDivisorConsultation";
import {
  GlobalOptions,
  MenuItems,
} from "../shared/interfaces/graphql.interface";
import { RespContainer } from "../styles/common";

// export default ({ pageContext, ...props }) => {

interface PageDataProps {
  pageContext: { data: PageContext };
}

interface PageContext {
  globalOptions: GlobalOptions;
  footerMenuItems: MenuItems;
  headerMenuItems: MenuItems;
  title: string;
  content: string;
}

const page: React.FC<PageDataProps> = ({ pageContext, ...props }) => {
  let pageContextData: PageContext = pageContext.data;
  return (
    <>
      <RespContainer style={{marginTop: "40px"}}>
        <h1 dangerouslySetInnerHTML={{ __html: pageContextData.title }} />
        <div dangerouslySetInnerHTML={{ __html: pageContextData.content }} />

        {/* <BannerRedesign
          title={"Web Design Made Easy"}
          title_2={pageContextData.acf.banner_title_2}
          subtitle={
            "Custom built full website design & development. We integrate the latest trends to bring you a modern website with intuitive design & practical functionality optimized for conversions"
          }
          banner={{
            source_url: "https://i.imgur.com/TnjAQj8.png",
            alt_text: "banner",
          }}
        /> */}
      </RespContainer>

      <SectionEzDivisorConsultation
        section={pageContextData.globalOptions.ez_divisor_consultation}
        phone={pageContextData.globalOptions.company_information.phone}
      />
    </>
  );
};

export default page;
